import request from '@/utils/request.js'
export const getsound = (params) => request.post('/api/sound/get_sound_list', params)
export const gettimbre = (params) => request.post('/api/sound/get_timbre_list', params)
export const craftAudio = (params) => request.post('/api/sound/submit_sound', params)
export const del_sound = (params) => request.post('/api/sound/del_sound', params)
export const upload_mp3 = (params) => request.post('/api/uploads/upload_mp3', params)
export const submits_sound = (params) => request.post('/api/sound/submits_sound', params)
export const get_cat = (params) => request.post('/api/sound/get_cat', params)


